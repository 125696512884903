.d-xs-none {
    display: initial !important;
}

.ws-0 {
    white-space: nowrap !important;
}

.c-pointer {
    cursor: pointer !important;
}

@media (max-width: 576px) {
    .d-xs-none {
        display: none !important;
    }
}